import useAuth from "@shared/AuthContext";
import { useEffect } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const usePageViews = (): void => {
  const { user } = useAuth();
  const sipcoAxiosService = useSipcoAxiosService();
  const pageCode = localStorage.getItem("pageCode") || "Página desconhecida";

  useEffect(() => {
    function formatDateTime(date: Date): string {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Os meses são de 0 a 11, então adicionamos 1
      const year = date.getFullYear();

      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    const clientCurrentTime = new Date();
    const formattedTime = formatDateTime(clientCurrentTime);

    const username = user.userName || "Usuário desconhecido";

    console.log(
      `Página visitada: ${pageCode} por ${username} hora: ${formattedTime}`
    );
    // Recupere o objeto de contagem de visitas atual do localStorage
    let visitCounts = JSON.parse(localStorage.getItem("visitCounts")) || {};
    // Atualize a contagem de visitas para a página atual
    visitCounts[pageCode] = (visitCounts[pageCode] || 0) + 1;
    // Salve o objeto de contagem de visitas atualizadoa de volta ao localStorage
    localStorage.setItem("visitCounts", JSON.stringify(visitCounts));
    // Enviar requisição POST para "/logPageVisit"
    sipcoAxiosService.post("/logPageVisit", {
      page: pageCode,
      user: username,
      clientCurrentTime: formattedTime,
    });
  }, [pageCode]);
};

export default usePageViews;
