import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const TODOS_OBJ = {
  label: "TODOS",
  id: 0,
  descripcion: 0,
};

export function ListadoOperacionesRealizadas() {
  const SCREEN_CODE = "con0041_listado_operaciones_realizadas";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  usePageViews();

  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterLabelId = [2621, 2622, 25183, 1799, 2693, 427, 441, 396];
  const filterNames = [];

  const columnsResumido = [
    13324, 54, 4791, 1491, 396, 25184, 13514, 2139, 2140, 25185, 367, 25186,
    3410, 25187, 18356, 14456, 18308, 2765, 3625, 31, 3344, 16580, 6813, 16789,
    16790, 2765, 16791, 15739, 5366, 13706, 5271, 19690,
  ];
  const columnsDetallado = [
    13324, 54, 4791, 1491, 396, 25184, 13514, 2139, 2140, 25185, 367, 25186,
    3410, 25187, 18356, 14456, 18308, 2765, 3625, 31, 3344, 16580, 6813, 16789,
    16790, 2765, 16791, 15739, 5788, 32, 1317, 5366, 13706, 5271, 19690,
  ];

  const handleDetalladoChange = (e) => {
    setFilter({ ...filter, detallado: e.value });
    if (e.value.id === "1") {
      //DETALLADO
      setColumnsToShow(columnsDetallado);
    } else if (e.value.id === "0") {
      //RESUMIDO
      setColumnsToShow(columnsResumido);
    }
  };

  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of columnsDetallado) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    4102, 427, 1799, 754, 2765, 3625, 5366, 18308, 14456, 18356, 2396, 2140, 31,
    10531, 1131, 65, 5583, 5044, 54, 1791, 2131, 367, 2496, 396, 748, 749, 1491,
    845, 4300, 2639, 1582, 13324, 4791, 13514, 2139, 31, 3344, 16580, 6813,
    16789, 16790, 2765, 16791, 13706, 5271, 32, 1317, 467, 468, 683, 441, 408,
    5788, 3410, 15739, 19690, 2753,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    detallado: null,
    grupoOperacion: TODOS_OBJ,
    operacion: [],
    proveedores: [],
    playas: [],
    marcas: [],
  });

  const [loading, setLoading] = useState(false);

  const [columnsToShow, setColumnsToShow] = useState(columnsDetallado);

  const [detallado, setDetallado] = useState([]);
  const [grupo, setGrupo] = useState([]);
  const [operacion, setOperacion] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [playa, setPlaya] = useState([]);
  const [marca, setMarca] = useState([]);

  const [loadingDetallado, setLoadingDetallado] = useState(true);
  const [loadingGrupo, setLoadingGrupo] = useState(true);
  const [loadingOperacion, setLoadingOperacion] = useState(true);
  const [loadingProveedores, setLoadingProveedores] = useState(true);
  const [loadingPlaya, setLoadingPlaya] = useState(true);
  const [loadingMarca, setLoadingMarca] = useState(true);

  async function searchDetallado(event: any) {
    await loadDetallado(event.query);
  }
  async function loadDetallado(query = "") {
    setLoadingDetallado(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/detallado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const detallado = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          if (query) {
            const detalladoFiltrados = detallado.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setDetallado(detalladoFiltrados);
            return detalladoFiltrados;
          } else {
            setDetallado(detallado);
            return detallado;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDetallado(false);
    }
    return [];
  }

  async function searchGrupo(event: any) {
    await loadGrupo(event.query);
  }
  async function loadGrupo(query = "") {
    setLoadingGrupo(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/grupo-operacion",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const grupo = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          grupo.unshift({
            label: "TODOS",
            id: 0,
            descripcion: 0,
          });

          if (query) {
            const grupoFiltrados = grupo.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setGrupo(grupoFiltrados);
            return grupoFiltrados;
          } else {
            setGrupo(grupo);
            return grupo;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrupo(false);
    }
    return [];
  }

  async function loadOperacion(grupoOperacion: any) {
    setLoadingOperacion(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/operacion",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              grupoOperacion: grupoOperacion,
            },
          }
        );

        if (status === 200) {
          const operacion = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setOperacion(operacion);
          return operacion;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOperacion(false);
    }
    return [];
  }

  async function loadProveedores() {
    setLoadingProveedores(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/proveedor",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          setProveedores(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedores(false);
    }
    return [];
  }

  async function loadPlaya() {
    setLoadingPlaya(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get("/combo/playa", {
          params: {
            codCuenta: securityFilters?.securityValues?.account?.id,
          },
        });

        if (status === 200) {
          setPlaya(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlaya(false);
    }
    return [];
  }

  async function loadMarca() {
    setLoadingMarca(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get("/combo/marca", {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id || "TODOS",
          },
        });

        if (status === 200) {
          setMarca(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const operacions = await loadOperacion(0);
          const detallados = await loadDetallado();
          const grupos = await loadGrupo();
          const proveedores = await loadProveedores();
          const playas = await loadPlaya();
          const marcas = await loadMarca();

          setFilter({
            ...filter,
            operacion: operacions,
            detallado: detallados[0],
            grupoOperacion: grupos[0],
            proveedores: proveedores,
            playas: playas,
            marcas: marcas,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  async function handleGrupoSelection(e: MultiSelectChangeEvent) {
    try {
      const selectedGrupo = e.value;

      if (selectedGrupo) {
        const operacion = await loadOperacion(selectedGrupo.id);

        setFilter({
          ...filter,
          grupoOperacion: selectedGrupo,
          operacion,
        });
      } else {
        setFilter({
          ...filter,
          grupoOperacion: null,
          operacion: [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.description || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,

        rangeFecha: [filter?.fechaDesde, filter?.fechaHasta],
        detallado: filter?.detallado?.id || null,
        grupoOperacion: filter?.grupoOperacion?.id || 0,
        operacion: filter?.operacion?.map((x) => x.id) || [],
        proveedores: filter?.proveedores?.map((x) => x.id) || [],
        playas: filter?.playas?.map((x) => x.id) || [],
        marcas: filter?.marcas?.map((x) => x.id) || [],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/report/operaciones-realizadas",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[0].label} alt="Desde" />
              </label>
              <Calendar
                value={filter.fechaDesde}
                onChange={(e) => {
                  setFilter({ ...filter, fechaDesde: e.value });
                }}
                showButtonBar
                showIcon
              />
            </div>
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[1].label} alt="Hasta" />
              </label>
              <Calendar
                value={filter.fechaHasta}
                onChange={(e) => {
                  setFilter({ ...filter, fechaHasta: e.value });
                }}
                showButtonBar
                showIcon
              />
            </div>

            <div className={"sipco-option"} style={{ maxWidth: "15%" }}>
              <label>
                <LanguageProvider
                  id={filterNames[2].label}
                  alt="Detallado/Resumido"
                />
              </label>
              <AutoComplete
                value={filter.detallado}
                suggestions={detallado}
                completeMethod={searchDetallado}
                onChange={handleDetalladoChange}
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[3].label} alt="Grupo" />
              </label>
              <AutoComplete
                value={filter.grupoOperacion}
                suggestions={grupo}
                completeMethod={searchGrupo}
                onChange={handleGrupoSelection}
                field="label"
                dropdown
                // forceSelection //Nao se aplica
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[4].label} alt="Operaciones" />
              </label>
              <MultiSelect
                value={filter.operacion}
                options={operacion}
                onChange={(e) => {
                  setFilter({ ...filter, operacion: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingOperacion}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[5].label} alt="Proveedores" />
              </label>
              <MultiSelect
                value={filter.proveedores}
                options={proveedores}
                onChange={(e) => {
                  setFilter({ ...filter, proveedores: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingProveedores}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[6].label} alt="Playas" />
              </label>
              <MultiSelect
                value={filter.playas}
                options={playa}
                onChange={(e) => {
                  setFilter({ ...filter, playas: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingPlaya}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[7].label} alt="Marca" />
              </label>
              <MultiSelect
                value={filter.marcas}
                options={marca}
                onChange={(e) => {
                  setFilter({ ...filter, marcas: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingMarca}
                showClear
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[56].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Operaciones Realizadas"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loading}
          filterDisplay="menu"
        >
          {columnsToShow.map((columnId, index) => (
            <Column
              key={index}
              field={index.toString()}
              header={columnsNames.find((col) => col.id === columnId)?.label}
              sortable
              filter
            />
          ))}
        </DataTable>
      </Panel>
    </div>
  );
}
export default ListadoOperacionesRealizadas;
