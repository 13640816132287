import "./DaniosCheckpoint.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function DaniosCheckpoint() {
  const SCREEN_CODE = "con0028_danios_x_checkpoint";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    11314, 1358, 6070, 467, 468, 10774, 2496, 396, 754, 5044,
  ];
  const filterNames = [];
  const filterColumnsId = [
    1166, 1386, 1387, 1388, 1389, 2131, 6141, 6142, 6143, 6144, 6145, 6146,
    6147, 6148, 6149, 6150,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    2131, 6141, 6142, 6143, 6144, 6145, 6146, 6147, 6148, 6149, 6150, 1082,
    6147, 54, 65, 396, 467, 468, 748, 749, 754, 1166, 1182, 1358, 1386, 1387,
    1388, 1389, 1791, 2496, 5044, 5583, 6070, 10021, 10531, 10774, 11314,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    checkpoint: null,
    consultaPorCheckpoint: true,
    startDate: null,
    endDate: null,
    mercados: [],
    origenes: [],
    marcas: [],
    familias: [],
    transportista: null,
    pais: null,
  });

  const [loading, setLoading] = useState(false);

  const [checkpoints, setCheckpoints] = useState([]);
  const [mercados, setMercados] = useState([]);
  const [origenes, setOrigenes] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [transportistas, setTransportistas] = useState([]);
  const [paises, setPaises] = useState([]);
  const [familias, setFamilias] = useState([]);

  const [loadingMercados, setLoadingMercados] = useState(false);
  const [loadingOrigens, setLoadingOrigens] = useState(false);
  const [loadingCheckpoints, setLoadingCheckpoints] = useState(false);
  const [loadingBrands, setLoadingBrands] = useState(false);
  const [loadingFamilies, setLoadingFamilies] = useState(false);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [loadingTransportistas, setLoadingTransportistas] = useState(false);

  async function loadCheckpoints() {
    setLoadingCheckpoints(true);
    try {
      if (securityFilters.securityValues.account) {
        const { status, data } = await sipcoAxiosService.get(
          "/danios-checkpoint/checkpoint",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
            },
          }
        );
        if (status === 200) {
          setCheckpoints(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCheckpoints(false);
    }
  }
  async function loadOrigen() {
    setLoadingOrigens(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/danios-checkpoint/origen",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setOrigenes(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigens(false);
    }
  }
  async function loadBrands() {
    setLoadingBrands(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/danios-checkpoint/marcas",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );
        if (status === 200) {
          setMarcas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBrands(false);
    }
  }
  async function loadTransportista() {
    setLoadingTransportistas(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/danios-checkpoint/provedor",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcion: "Todos" });
          setTransportistas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransportistas(false);
    }
  }
  async function loadPais() {
    setLoadingCountries(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/danios-checkpoint/pais",
        { params: { description: "" } }
      );
      if (status === 200) {
        data.unshift({ id: 0, descripcion: "Todos" });
        setPaises(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCountries(false);
    }
  }
  async function loadFamilias() {
    setLoadingFamilies(true);
    try {
      if (
        filter.marcas.length === 1 &&
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client
      ) {
        const selectedMarca = filter.marcas[0];
        const { status, data } = await sipcoAxiosService.get(
          "/danios-checkpoint/familias",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: selectedMarca.id,
            },
          }
        );
        if (status === 200) {
          setFamilias(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFamilies(false);
    }
  }
  async function loadMercados() {
    setLoadingMercados(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/danios-checkpoint/mercado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setMercados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
  }

  async function loadReportData() {
    try {
      setLoading(true);
      console.log("checkpoint", filter.checkpoint);

      const { status, data } = await sipcoAxiosService.post(
        "danios-checkpoint/report",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCliente: securityFilters?.securityValues?.client?.id || "",
          clienteDescripcion:
            securityFilters?.securityValues?.client?.description || "",
          codCuenta: securityFilters?.securityValues?.account?.id || null,
          checkpoint:
            filter?.checkpoint?.id === 0 ? 0 : filter?.checkpoint?.id || null,
          consultaPorCheckPoint: filter.consultaPorCheckpoint,
          rangeFecha: [filter.startDate, filter.endDate],
          mercado: filter?.mercados?.map((x) => x.id) || [],
          origens: filter?.origenes?.map((x) => x.id) || [],
          marcas: filter?.marcas?.map((x) => x.id) || [],
          familias: filter?.familias?.map((x) => x.id) || [],
          transportista: filter?.transportista?.id || null,
          paisFiltro: filter?.pais?.id || null,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const mercados = await loadMercados();
          const origins = await loadOrigen();
          const checkpoinst = await loadCheckpoints();
          const brands = await loadBrands();
          setFilter({
            ...filter,
            mercados: mercados,
            origenes: origins,
            checkpoint: checkpoinst[0],
            marcas: brands,
            startDate: new Date(),
            endDate: new Date(),
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  useEffect(() => {
    async function handleMarcaSection() {
      if (filter?.marcas?.length === 1) {
        const families = await loadFamilias();
        setFilter({
          ...filter,
          familias: families,
        });
      }
    }
    handleMarcaSection();
  }, [filter.marcas]);
  useEffect(() => {
    async function handleQueryTypeChange() {
      if (!filter?.consultaPorCheckpoint) {
        const countries = await loadPais();
        const transportistas = await loadTransportista();
        if (countries?.length > 0 && transportistas?.length > 0) {
          setFilter({
            ...filter,
            pais: countries[0],
            transportista: transportistas[0],
          });
        }
      }
    }
    handleQueryTypeChange();
  }, [filter.consultaPorCheckpoint, securityFilters.securityValues]);

  return (
    <div className="danios-x-checkpoint">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[0].id} alt="Checkpoint" />
                </label>
                <Dropdown
                  value={filter.checkpoint}
                  options={checkpoints}
                  onChange={(e) =>
                    setFilter({ ...filter, checkpoint: e.value })
                  }
                  optionLabel="label"
                  loading={loadingCheckpoints}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="RadioButtonFilter">
                <RadioButton
                  checked={filter.consultaPorCheckpoint}
                  onChange={(e) => {
                    setFilter({ ...filter, consultaPorCheckpoint: true });
                    setReporte([]);
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider
                    id={filterNames[1].label}
                    alt="Consulta de daños por checkpoint"
                  />
                </label>
                <RadioButton
                  checked={!filter.consultaPorCheckpoint}
                  onChange={(e) => {
                    setFilter({ ...filter, consultaPorCheckpoint: false });
                    setReporte([]);
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider
                    id={filterNames[2].label}
                    alt="Cargar Estadisticas por CP"
                  />
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <Calendar
                  value={filter.startDate}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, startDate: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <Calendar
                  value={filter.endDate}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, endDate: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[5].id} alt="Mercados" />
                </label>
                <MultiSelect
                  value={filter.mercados}
                  options={mercados}
                  onChange={(e) => {
                    setFilter({ ...filter, mercados: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingMercados}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[6].id} alt="Origen" />
                </label>
                <MultiSelect
                  value={filter.origenes}
                  options={origenes}
                  onChange={(e) => {
                    setFilter({ ...filter, origenes: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingOrigens}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[7].id} alt="Marca" />
                </label>
                <MultiSelect
                  value={filter.marcas}
                  options={marcas}
                  onChange={(e) => {
                    setFilter({ ...filter, marcas: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingBrands}
                  showClear
                />
              </div>
              {filter?.marcas?.length === 1 && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"10021"} alt="Familias" />
                  </label>
                  <MultiSelect
                    value={filter.familias}
                    options={familias}
                    onChange={(e) => {
                      setFilter({ ...filter, familias: e.value });
                    }}
                    display="chip"
                    filter
                    optionLabel="label"
                    loading={loadingFamilies}
                    showClear
                  />
                </div>
              )}
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[8].id}
                    alt="Transportista"
                  />
                </label>
                <Dropdown
                  value={filter.transportista}
                  options={transportistas}
                  onChange={(e) =>
                    setFilter({ ...filter, transportista: e.value })
                  }
                  optionLabel="descripcion"
                  disabled={filter.consultaPorCheckpoint}
                  loading={loadingTransportistas}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[9].id} alt="Pais" />
                </label>
                <Dropdown
                  value={filter.pais}
                  options={paises}
                  onChange={(e) => setFilter({ ...filter, pais: e.value })}
                  optionLabel="descripcion"
                  disabled={
                    filter.consultaPorCheckpoint ||
                    !securityFilters?.securityValues?.client?.id
                  }
                  loading={loadingCountries}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider
                  id="1358"
                  alt="Consulta de daños por checkpoint"
                />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"DanioXCheckpoint"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        {!filter.consultaPorCheckpoint ? (
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={data}
            paginator
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
            resizableColumns
            removableSort
            filterDisplay="menu"
          >
            <Column field="0" header={columnsNames[0].label} sortable filter />
            <Column
              field="1"
              header={columnsNames[1].label}
              sortable
              filter
              footer={data.reduce((sum, row) => sum + row[1], 0)}
            />
            <Column
              field="2"
              header={columnsNames[2].label}
              sortable
              filter
              footer={data.reduce((sum, row) => sum + row[2], 0)}
            />
            <Column
              field="3"
              header={columnsNames[3].label}
              sortable
              filter
              footer={data.reduce((sum, row) => sum + row[3], 0)}
            />
            <Column
              field="4"
              header={columnsNames[4].label}
              sortable
              filter
              footer={data.reduce((sum, row) => sum + row[4], 0)}
            />
            <Column
              field="5"
              header={columnsNames[5].label}
              sortable
              filter
              footer={data.reduce((sum, row) => sum + row[5], 0)}
            />
            <Column
              field="6"
              header={columnsNames[6].label}
              sortable
              filter
              footer={data.reduce((sum, row) => sum + row[6], 0)}
            />
            <Column
              field="7"
              header={columnsNames[7].label}
              sortable
              filter
              footer={data.reduce((sum, row) => sum + row[7], 0)}
            />
            <Column
              field="8"
              header={columnsNames[8].label}
              sortable
              filter
              footer={data.reduce((sum, row) => sum + row[8], 0)}
            />
            <Column
              field="9"
              header={columnsNames[9].label}
              sortable
              filter
              footer={data.reduce((sum, row) => sum + row[9], 0)}
            />
            <Column
              field="10"
              header={columnsNames[10].label}
              sortable
              filter
              footer={data.reduce((sum, row) => sum + row[10], 0)}
            />
            <Column
              field="11"
              header={columnsNames[11].label}
              sortable
              filter
              footer={data.reduce((sum, row) => sum + row[11], 0)}
            />
            <Column
              field="12"
              header={columnsNames[12].label}
              sortable
              filter
              footer={data.reduce((sum, row) => sum + row[12], 0)}
            />
            <Column
              field="13"
              header={columnsNames[13].label}
              sortable
              filter
              footer={data.reduce((sum, row) => sum + row[13], 0)}
            />
            <Column
              field="14"
              header={columnsNames[14].label}
              sortable
              filter
            />
            <Column
              field="15"
              header={columnsNames[15].label}
              sortable
              filter
            />
          </DataTable>
        ) : (
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={data}
            paginator
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            loading={loading}
            resizableColumns
            removableSort
            filterDisplay="menu"
          >
            <Column
              field="0"
              header={columnsNames[0].label}
              sortable
              filter
              body={(rowdata) => {
                if (rowdata[0]) {
                  return rowdata[0].replace("00:00:00", "");
                }
              }}
            />
            <Column field="1" header={columnsNames[1].label} sortable filter />
            <Column field="2" header={columnsNames[2].label} sortable filter />
            <Column
              field="3"
              header={columnsNames[3].label}
              sortable
              filter
              body={(rowdata) => {
                if (rowdata[3]) {
                  return parseFloat(rowdata[3]).toPrecision(2);
                }
              }}
            />
            <Column field="4" header={columnsNames[4].label} sortable filter />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
export default DaniosCheckpoint;
