import React, { createContext, useContext, useState } from "react";

interface ScreenCodeProviderProps {
    children: React.ReactNode;
}

interface ScreenCodeContextDate {
    screenCode: string;
    setScreenCode: React.Dispatch<React.SetStateAction<string>>;
}

const ScreenCodeContext = createContext({} as ScreenCodeContextDate);

export const ScreenCodeProvider : React.FC<ScreenCodeProviderProps> = ({children} : {children : React.ReactNode}) => {
    const [screenCode, setScreenCode] = useState<string>('');

    return(
        <ScreenCodeContext.Provider value={{screenCode, setScreenCode}}>
            {children}
        </ScreenCodeContext.Provider>
    )
    
}

export const useScreenCodeContext = () =>  useContext(ScreenCodeContext);
