import React, { useState } from "react";
import { LanguageProvider } from "@shared/components/language-provider";

const Home = () => {
  const data = JSON.parse(localStorage.getItem("loginData") || "{}");
  const [showData, setShowData] = useState(false);

  const mensagens = [];
  const ids = [25180, 25181]; //Ocultar Datos : Mostrar Datos
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  return (
    <div className="home">
      <button onClick={() => setShowData(!showData)}>
        {showData ? mensagens[0].label : mensagens[1].label}
      </button>
      {showData &&
        Object.entries(data).map(([key, value]) => {
          if (value && typeof value === "object") {
            return (
              <div key={key}>
                <h2>{key}</h2>
                {Object.entries(value).map(([subKey, subValue]) => (
                  <p key={subKey}>{`${subKey}: ${subValue}`}</p>
                ))}
              </div>
            );
          } else {
            return <p key={key}>{`${key}: ${value}`}</p>;
          }
        })}
    </div>
  );
};

export default Home;
