import "./scss/loading-indicator.scss";

const LoadingIndicator = ({ color = "#4147FF", size = "medium" }) => {
  return (
    <div className="loading-indicator-container">
      <div
        className={`loading-indicator loading-indicator-${size}`}
        style={{ "--spinnerColor": color }}
      />
    </div>
  );
};

export default LoadingIndicator;
