import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { inactivoColorT } from "../../../hooks/useInactivoColor";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const TODOS_OBJ = {
  label: "TODOS",
  id: -1,
  descripcion: "TODOS",
};

export function StockTaller() {
  const SCREEN_CODE = "con0042_stock_taller";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterLabelId = [327, 2183];
  const filterNames = [];
  const filterColumnsId = [16476, 10829, 31, 2183, 1253, 2265, 2890];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 5044, 54, 1791, 4791, 327, 2183, 16476, 10829, 31, 1253, 2183,
    2265, 2890, 1002, 2911,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    taller: null,
    rubro: TODOS_OBJ,
  });

  const [loading, setLoading] = useState(false);

  const [loadingCodTaller, setLoadingCodTaller] = useState(true);
  const [codTaller, setCodTaller] = useState([]);
  const [loadingCodRubro, setLoadingCodRubro] = useState(true);
  const [codRubro, setCodRubro] = useState([]);

  async function searchCodTaller(event: any) {
    await loadCodTaller(event.query);
  }
  async function loadCodTaller(query = "") {
    setLoadingCodTaller(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/report/taller-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters?.securityValues?.account?.id,
              codSubcuenta: securityFilters?.securityValues?.subaccount?.id,
            },
          }
        );

        if (status === 200) {
          const codTaller = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          if (query) {
            const codTallerFiltrados = codTaller.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setCodTaller(codTallerFiltrados);
            return codTallerFiltrados;
          } else {
            setCodTaller(codTaller);
            return codTaller;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCodTaller(false);
    }
    return [];
  }

  async function searchCodRubro(event: any) {
    await loadCodRubro(event.query);
  }
  async function loadCodRubro(query = "") {
    setLoadingCodRubro(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/report/rubro-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const codRubro = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          codRubro.unshift(TODOS_OBJ);

          if (query) {
            const codRubroFiltrados = codRubro.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setCodRubro(codRubroFiltrados);
            return codRubroFiltrados;
          } else {
            setCodRubro(codRubro);
            return codRubro;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCodRubro(false);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const tallers = await loadCodTaller();

          setFilter({
            ...filter,
            taller: tallers[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.description || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,

        codTaller: filter?.taller?.id || null,
        codRubro: null,
      };

      if (filter.rubro != null && filter.rubro.id !== -1) {
        filters.codRubro = filter.rubro.id;
      }

      const { status, data } = await sipcoAxiosService.post(
        "/report/stock-taller",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          allClientsOptions: false,
          allSubaccountsOptions: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[0].label} alt="Taller" />
              </label>
              <AutoComplete
                value={filter.taller}
                suggestions={codTaller}
                completeMethod={searchCodTaller}
                onChange={(e) => setFilter({ ...filter, taller: e.value })}
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[1].label} alt="Rubro" />
              </label>
              <AutoComplete
                value={filter.rubro}
                suggestions={codRubro}
                completeMethod={searchCodRubro}
                onChange={(e) => setFilter({ ...filter, rubro: e.value })}
                field="label"
                dropdown
                forceSelection
                itemTemplate={inactivoColorT}
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[16].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Stock en Taller"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          loading={loading}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default StockTaller;
