import "./ListadoArmadoViajes.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ListadoArmadoViajes() {
  const SCREEN_CODE = "con0051_listado_armado_viajes";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterLabelId = [
    10527, 10528, 1617, 10774, 1151, 266, 1285, 2622, 25188, 2621, 2622, 382,
    2621, 2622, 25189, 2621, 2622,
  ];
  const filterNames = [];
  const filterColumnsId = [
    54, 0, 0, 10502, 0, 164, 3186, 10509, 10508, 10507, 10506, 492, 1491, 932,
    441, 2139, 4179, 1284, 924, 4145, 1492, 2496,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    10750, 6697, 54, 65, 164, 266, 382, 441, 467, 468, 530, 748, 749, 924, 932,
    1151, 1182, 1284, 1285, 1491, 1617, 1791, 2139, 2502, 3186, 4145, 4179,
    5044, 5583, 10502, 10506, 10507, 10508, 10509, 10527, 10528, 10531, 10774,
    16531, 16842, 1492, 2496, 492, 10494,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    radioButton: false,

    tipoRemito: [],
    mercado: [],
    estadoViaje: [],
    concesionarios: [],

    llegadaDesde: new Date(),
    llegadaHasta: new Date(),

    liberacion: false,
    fechaLiberacionDesde: new Date(),
    fechaLiberacioHasta: new Date(),

    despacho: false,
    fechaDespachoDesde: new Date(),
    fechaDespachoHasta: new Date(),

    cargaRetorno: false,
    fechaCargaRetornoDesde: new Date(),
    fechaCargaRetornoHasta: new Date(),
  });

  const [loading, setLoading] = useState(false);

  const [tipoRemito, setTipoRemito] = useState([]);
  const [mercado, setMercado] = useState([]);
  const [estado, setEstado] = useState([]);
  const [concesionario, setConcesionario] = useState([]);

  const [loadingTipoRemito, setLoadingTipoRemito] = useState(true);
  const [loadingMercado, setLoadingMercado] = useState(true);
  const [loadingEstado, setLoadingEstado] = useState(true);
  const [loadingConcesionario, setLoadingConcesionario] = useState(true);

  async function loadTipoRemito(query = "") {
    setLoadingTipoRemito(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/listado-armado-viajes/combo/tipo-remito",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const tipoRemito = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });

          if (query) {
            const tipoRemitoFiltrados = tipoRemito.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setTipoRemito(tipoRemitoFiltrados);
            return tipoRemitoFiltrados;
          } else {
            setTipoRemito(tipoRemito);
            return tipoRemito;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoRemito(false);
    }
    return [];
  }

  async function loadMercado(query = "") {
    setLoadingMercado(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/listado-armado-viajes/combo/mercado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const mercado = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });

          if (query) {
            const mercadoFiltrados = mercado.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setMercado(mercadoFiltrados);
            return mercadoFiltrados;
          } else {
            setMercado(mercado);
            return mercado;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercado(false);
    }
    return [];
  }

  async function loadEstado(query = "") {
    setLoadingEstado(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/listado-armado-viajes/combo/estado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const estado = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });

          if (query) {
            const estadoFiltrados = estado.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setEstado(estadoFiltrados);
            return estadoFiltrados;
          } else {
            setEstado(estado);
            return estado;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstado(false);
    }
    return [];
  }

  async function loadConcesionario(query = "") {
    setLoadingConcesionario(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/listado-armado-viajes/combo/concesionario",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );

        if (status === 200) {
          const concesionario = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });

          if (query) {
            const concesionarioFiltrados = concesionario.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setConcesionario(concesionarioFiltrados);
            return concesionarioFiltrados;
          } else {
            setConcesionario(concesionario);
            return concesionario;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConcesionario(false);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const tipoRemitos = await loadTipoRemito();
          const mercados = await loadMercado();
          const estados = await loadEstado();
          const concesionarios = await loadConcesionario();

          setFilter({
            ...filter,
            tipoRemito: tipoRemitos,
            mercado: mercados,
            estadoViaje: estados,
            concesionarios: concesionarios,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.description || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,

        radioButton: filter.radioButton
          ? "Días totales a vencer para despacho"
          : "Días totales a vencer para entregas",
        tipoRemito: filter?.tipoRemito?.map((x) => x.id) || [],
        mercado: filter?.mercado?.map((x) => x.id) || [],
        estadoViaje: filter?.estadoViaje?.map((x) => x.id) || [],
        concesionarios: filter?.concesionarios?.map((x) => x.id) || [],
        rangeFecha: [filter?.llegadaDesde, filter?.llegadaHasta],
        liberacionBoolean: filter.liberacion ? true : false,
        despachoBoolean: filter.despacho ? true : false,
        cargaRetornoBoolean: filter.cargaRetorno ? true : false,
        fechaLiberacion: [
          filter?.fechaLiberacionDesde,
          filter?.fechaLiberacioHasta,
        ],
        fechaDespacho: [filter?.fechaDespachoDesde, filter?.fechaDespachoHasta],
        fechaCargaRetorno: [
          filter?.fechaCargaRetornoDesde,
          filter?.fechaCargaRetornoHasta,
        ],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/listado-armado-viajes/report",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const clientIsTodos =
    securityFilters.securityValues?.client?.descripcion === "TODOS";

  return (
    <div className="listado-armado-viajes">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="RadioButtonFilter">
              <RadioButton
                checked={!filter.radioButton}
                onChange={(e) => {
                  setFilter({ ...filter, radioButton: false });
                  setReporte([]);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={filterNames[0].label} />
              </label>
              <RadioButton
                checked={filter.radioButton}
                onChange={(e) => {
                  setFilter({ ...filter, radioButton: true });
                  setReporte([]);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={filterNames[1].label} />
              </label>
            </div>

            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[2].label}
                    alt="Tipo remito"
                  />
                </label>
                <MultiSelect
                  value={filter.tipoRemito}
                  options={tipoRemito}
                  onChange={(e) => {
                    setFilter({ ...filter, tipoRemito: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingTipoRemito}
                  showClear
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[3].label} alt="Mercados" />
                </label>
                <MultiSelect
                  value={filter.mercado}
                  options={mercado}
                  onChange={(e) => {
                    setFilter({ ...filter, mercado: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingMercado}
                  showClear
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[4].label} alt="Estados" />
                </label>
                <MultiSelect
                  value={filter.estadoViaje}
                  options={estado}
                  onChange={(e) => {
                    setFilter({ ...filter, estadoViaje: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingEstado}
                  showClear
                />
              </div>

              {!clientIsTodos && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider
                      id={filterNames[5].label}
                      alt="Concesionarios"
                    />
                  </label>
                  <MultiSelect
                    value={filter.concesionarios}
                    options={concesionario}
                    onChange={(e) => {
                      setFilter({ ...filter, concesionarios: e.value });
                    }}
                    display="chip"
                    filter
                    optionLabel="label"
                    loading={loadingConcesionario}
                    showClear
                  />
                </div>
              )}
            </div>

            <div className="sipco-options-line">
              <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
                <label>
                  <LanguageProvider
                    id={filterNames[6].label}
                    alt="Llegada Desde"
                  />
                </label>
                <Calendar
                  value={filter.llegadaDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, llegadaDesde: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
                <label>
                  <LanguageProvider id={filterNames[7].label} alt="Hasta" />
                </label>
                <Calendar
                  value={filter.llegadaHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, llegadaHasta: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
            </div>

            {/* Liberacion Desde */}
            <div className="sipco-options-line">
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.liberacion}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      liberacion: !filter.liberacion,
                    });
                    setReporte([]);
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={filterNames[8].label} />
                </label>
              </div>
              <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
                <label>
                  <LanguageProvider id={filterNames[9].label} alt="Desde" />
                </label>
                <Calendar
                  value={filter.fechaLiberacionDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaLiberacionDesde: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.liberacion}
                />
              </div>
              <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
                <label>
                  <LanguageProvider id={filterNames[10].label} alt="Hasta" />
                </label>
                <Calendar
                  value={filter.fechaLiberacioHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaLiberacioHasta: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.liberacion}
                />
              </div>
            </div>

            {/* Despacho Desde */}
            <div className="sipco-options-line">
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.despacho}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      despacho: !filter.despacho,
                    });
                    setReporte([]);
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={filterNames[11].label} />
                </label>
              </div>
              <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
                <label>
                  <LanguageProvider id={filterNames[12].label} alt="Desde" />
                </label>
                <Calendar
                  value={filter.fechaDespachoDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaDespachoDesde: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.despacho}
                />
              </div>
              <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
                <label>
                  <LanguageProvider id={filterNames[13].label} alt="Hasta" />
                </label>
                <Calendar
                  value={filter.fechaDespachoHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaDespachoHasta: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.despacho}
                />
              </div>
            </div>

            {/* Carga Retorno Desde */}
            <div className="sipco-options-line">
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.cargaRetorno}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      cargaRetorno: !filter.cargaRetorno,
                    });
                    setReporte([]);
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={filterNames[14].label} />
                </label>
              </div>
              <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
                <label>
                  <LanguageProvider id={filterNames[15].label} alt="Desde" />
                </label>
                <Calendar
                  value={filter.fechaCargaRetornoDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaCargaRetornoDesde: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.cargaRetorno}
                />
              </div>
              <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
                <label>
                  <LanguageProvider id={filterNames[16].label} alt="Hasta" />
                </label>
                <Calendar
                  value={filter.fechaCargaRetornoHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaCargaRetornoHasta: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.cargaRetorno}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[43].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Listado de Armado de Viajes"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loading}
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          {/* <Column field="1" header={columnsNames[1].label} sortable filter /> */}
          {/* <Column field="2" header={columnsNames[2].label} sortable filter /> */}
          <Column field="3" header={columnsNames[3].label} sortable filter />
          {/* <Column field="4" header={columnsNames[4].label} sortable filter /> */}
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ListadoArmadoViajes;
