import { useEffect } from "react";
import { useScreenCodeContext } from "./ScrennContext";

export const ScreenCodeValue = (value: string) => {
    const { setScreenCode } = useScreenCodeContext();

    useEffect(() => {
        setScreenCode(value);
    }, [setScreenCode]);
    
    return;
}