import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function TiemposCentroPerdidos() {
  const SCREEN_CODE = "con0096_transit_time_pedidos_playa";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [13859, 1491, 22137, 382, 16842, 266, 10904];
  const filterNames = [];
  const filterColumnsId = [
    22137, 10871, 2941, 10868, 10904, 23369, 23370, 11372, 4976, 1669, 1491,
    11158, 3774, 10898, 16925, 10577, 17263, 22209, 18727, 17203, 2830, 22406,
    13706, 13706,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];

  const ids = [
    0, 901, 65, 54, 1791, 13859, 22137, 382, 16842, 266, 530, 748, 749, 5583,
    5044, 11372, 22137, 3774, 1491, 10871, 2941, 10868, 10904, 11158, 10898,
    10577, 17203, 4976, 22209, 18727, 1669, 653, 2830, 22406, 23369, 23370,
    15624, 3184, 3329, 17263, 13706, 16925,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div>
      <Filter
        onSearch={setReporte}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
        filterOptions={{
          urlApi: "/transit-time-pedido-playa/report",
          parametros: ["transitTimePedidoPlaya"],
          customFilters: [
            { label: filterNames[0].label, variable: "Fecha", type: "date" },
            {
              label: filterNames[1].label,
              variable: "VIN",
              type: "dropdown",
              parametros: ["codPais", "codCuenta", "codCliente", "VIN"],
              urlApi: "/transit-time-pedido-playa/combo/vin",
            },
            {
              label: filterNames[2].label,
              variable: "NroAssignacion",
              type: "text",
            },
            {
              label: filterNames[3].label,
              variable: "Despacho",
              type: "checkboxDate",
            },
            {
              label: filterNames[4].label,
              variable: "CargaRetorno",
              type: "checkboxDate",
            },
            {
              label: filterNames[5].label,
              variable: "Concesionarios",
              type: "multipleSelection",
              parametros: ["codPais", "codCliente"],
              urlApi: "/transit-time-pedido-playa/combo/concesionario",
              descripcionAddCode: true,
            },
            {
              label: filterNames[6].label,
              variable: "DirecionEntrega",
              type: "multipleSelection",
              parametros: ["codPais", "codConcesionarios", "codCliente"],
              urlApi: "/transit-time-pedido-playa/combo/direccion-entrega",
              descripcionAddCode: true,
            },
          ],
        }}
      />
      <Panel
        header="Tiempos de Centro Perdidos"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Tiempos de Centro Perdidos
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Tiempos de Centro Perdidos"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          {/*<Column field="" header={columnsNames[7].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[8].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[9].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[10].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[11].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[12].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[13].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[14].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[15].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[16].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[17].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[18].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[19].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[20].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[21].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[22].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[23].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[24].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[25].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[26].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[27].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[28].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[29].label} sortable filter/>*/}
        </DataTable>
      </Panel>
    </div>
  );
}
export default TiemposCentroPerdidos;
