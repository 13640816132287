import './PanelCustom.scss';
import React from 'react';
import { Card } from 'primereact/card';

/**
 * PanelCustom is a Card with a custom Header to simulate a primeReact <Panel> that will accept CSS customizations more easily.
 *
 * @param {object} props - The props for the component.
 * @param {React.ReactNode} props.header - The header content to be displayed in the Card.
 * @param {React.ReactNode} props.children - The main content to be displayed inside the Card.
 * @returns {JSX.Element} The rendered PanelCustom component.
 */
const PanelCustom = ({ header, children }) => {
    return (
        <Card className="panel-custom" header={<span className="card-text-header">{header}</span>}>
            {children}
        </Card>
    );
};

export default PanelCustom;
