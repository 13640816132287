import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ListadoHojaRuta() {
  const SCREEN_CODE = "con0047_ ";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterLabelId = [2131, 54, 504, 11787, 3344];
  const filterNames = [];
  const filterColumnsId = [
    924, 16453, 3344, 10136, 24859, 23097, 4285, 10902, 4655, 11787, 16454,
    16455, 3206, 24400, 16456, 54, 1491, 4724, 3184, 22927, 22928, 2941, 25012,
    1342, 15862, 25079, 10510, 14561, 504, 3344, 396, 2139, 24516, 10502, 5592,
    1849, 1850, 367, 18984, 23020, 159, 164, 3625, 5513,
  ];

  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    39, 54, 65, 159, 164, 396, 448, 504, 740, 743, 924, 1131, 1342, 1491, 2139,
    2621, 2622, 2941, 3184, 3344, 3625, 4285, 4655, 4724, 5044, 10136, 10502,
    10902, 11787, 14561, 15862, 16453, 16454, 16455, 16456, 16457, 16458, 16459,
    16460, 367, 1850, 1849, 5592, 18985, 22927, 22928, 23020, 23097, 24859,
    3206, 4145, 25012, 18984, 24400, 10510, 24516, 25079,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    fechaInicio: new Date(),
    fechaFinal: new Date(),
    cliente: null,
    tipoDespacho: null,
    proveedorTractor: null,
    estado: null,
  });

  const [loading, setLoading] = useState(false);

  const [cliente, setCliente] = useState([]);
  const [tipoDespacho, setTipoDespacho] = useState([]);
  const [proveedorTractor, setProveedorTractor] = useState([]);
  const [estado, setEstado] = useState([]);

  async function searchCliente(event: any) {
    await loadClientes(event.query);
  }
  async function loadClientes(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get("all-clientes", {
          params: {
            descripcion: query,
          },
        });

        if (status === 200) {
          const cliente = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.codCliente})` };
          });

          cliente.unshift({
            label: "TODOS",
            codCliente: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const clienteFiltrados = cliente.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setCliente(clienteFiltrados);
            return clienteFiltrados;
          } else {
            setCliente(cliente);
            return cliente;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function searchTipoDespacho(event: any) {
    await loadTipoDespachos(event.query);
  }
  async function loadTipoDespachos(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "tipo-despacho-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const tipoDespacho = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          tipoDespacho.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const tipoDespachoFiltrados = tipoDespacho.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setTipoDespacho(tipoDespachoFiltrados);
            return tipoDespachoFiltrados;
          } else {
            setTipoDespacho(tipoDespacho);
            return tipoDespacho;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function searchProveedorTractor(event: any) {
    await loadProveedorTractors(event.query);
  }
  async function loadProveedorTractors(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "proveedores-options",
          {
            params: {
              descripcion: "IT",
            },
          }
        );

        if (status === 200) {
          const proveedorTractor = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.codProveedor})` };
          });

          proveedorTractor.unshift({
            label: "TODOS",
            codProveedor: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const proveedorTractorFiltrados = proveedorTractor.filter(
              (x: any) => x.label.toLowerCase().includes(query.toLowerCase())
            );
            setProveedorTractor(proveedorTractorFiltrados);
            return proveedorTractorFiltrados;
          } else {
            setProveedorTractor(proveedorTractor);
            return proveedorTractor;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function searchEstado(event: any) {
    await loadEstados(event.query);
  }
  async function loadEstados(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "estado-hoja-ruta-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const estado = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          estado.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const estadoFiltrados = estado.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setEstado(estadoFiltrados);
            return estadoFiltrados;
          } else {
            setEstado(estado);
            return estado;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const clientes = await loadClientes();
          const tipoDespachos = await loadTipoDespachos();
          const proveedorTractors = await loadProveedorTractors();
          const estados = await loadEstados();

          setFilter({
            ...filter,
            cliente: clientes[0],
            tipoDespacho: tipoDespachos[0],
            proveedorTractor: proveedorTractors[0],
            estado: estados[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "/report/listado-hoja-ruta",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCliente: securityFilters?.securityValues?.client?.id || null,
          clienteDescripcion:
            securityFilters?.securityValues?.client?.description || "",
          codCuenta: securityFilters?.securityValues?.account?.id || null,

          rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
          cliente: filter?.cliente?.codCliente || null,
          tipoDespacho: filter?.tipoDespacho?.id || null,
          proveedorTractor: filter?.proveedorTractor?.codProveedor || null,
          estado: filter?.estado?.id || null,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={mensagens[15].id} alt="Desde" />
              </label>
              <Calendar
                value={filter.fechaInicio}
                onChange={(e) => {
                  setFilter({ ...filter, fechaInicio: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={mensagens[16].id} alt="Hasta" />
              </label>
              <Calendar
                value={filter.fechaFinal}
                onChange={(e) => {
                  setFilter({ ...filter, fechaFinal: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[1].id} alt="Cliente" />
              </label>
              <AutoComplete
                value={filter.cliente}
                suggestions={cliente}
                completeMethod={searchCliente}
                onChange={(e) => setFilter({ ...filter, cliente: e.value })}
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[2].id} alt="Tipo Despacho" />
              </label>
              <AutoComplete
                value={filter.tipoDespacho}
                suggestions={tipoDespacho}
                completeMethod={searchTipoDespacho}
                onChange={(e) =>
                  setFilter({ ...filter, tipoDespacho: e.value })
                }
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[3].id}
                  alt="Proveedor Tractor"
                />
              </label>
              <AutoComplete
                value={filter.proveedorTractor}
                suggestions={proveedorTractor}
                completeMethod={searchProveedorTractor}
                onChange={(e) =>
                  setFilter({ ...filter, proveedorTractor: e.value })
                }
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[4].id} alt="Estado" />
              </label>
              <AutoComplete
                value={filter.estado}
                suggestions={estado}
                completeMethod={searchEstado}
                onChange={(e) => setFilter({ ...filter, estado: e.value })}
                field="label"
                dropdown
                forceSelection
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {columnsNames[43].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Listado de hojas de ruta"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          loading={loading}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          <Column field="23" header={columnsNames[23].label} sortable filter />
          <Column field="24" header={columnsNames[24].label} sortable filter />
          <Column field="25" header={columnsNames[25].label} sortable filter />
          <Column field="26" header={columnsNames[26].label} sortable filter />
          <Column field="27" header={columnsNames[27].label} sortable filter />
          <Column field="28" header={columnsNames[28].label} sortable filter />
          <Column field="29" header={columnsNames[29].label} sortable filter />
          <Column field="30" header={columnsNames[30].label} sortable filter />
          <Column field="31" header={columnsNames[31].label} sortable filter />
          <Column field="32" header={columnsNames[32].label} sortable filter />
          <Column field="33" header={columnsNames[33].label} sortable filter />
          <Column field="34" header={columnsNames[34].label} sortable filter />
          <Column field="35" header={columnsNames[35].label} sortable filter />
          <Column field="36" header={columnsNames[36].label} sortable filter />
          <Column field="37" header={columnsNames[37].label} sortable filter />
          <Column field="38" header={columnsNames[38].label} sortable filter />
          <Column field="39" header={columnsNames[39].label} sortable filter />
          <Column field="40" header={columnsNames[40].label} sortable filter />
          <Column field="41" header={columnsNames[41].label} sortable filter />
          <Column field="42" header={columnsNames[42].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ListadoHojaRuta;
