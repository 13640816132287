import "./CargaOpForzadas.scss";

import * as XLSX from "xlsx";

import React, { useEffect, useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelect } from "primereact/multiselect";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function CargaOperacionesForzadas() {
  const fileInputRef = useRef(null);
  const toast = useRef<Toast>(null);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const [vin, setVin] = useState("");
  const [datasGrid, setDatasGrid] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [operaciones, setOperaciones] = useState([]);
  const [totalVins, setTotalVins] = useState(0);
  const [totalOperacoes, setTotalOperacoes] = useState(0);
  const [selectedOperacoes, setSelectedOperacoes] = useState([]);
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const dataFooter = convertDateObjects(reporte?.filas);
  const [nextId, setNextId] = useState(1);
  const [loadingCargaMassiva, setLoadingCargaMassiva] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState(false);

  usePageViews();
  const SCREEN_CODE = "fun0203_carga_operaciones_forzadas";
  ScreenCodeValue(SCREEN_CODE);

  const showSuccess = (detail: string) => {
    toast.current.show({
      severity: "success",
      summary: "Sucesso",
      detail: detail,
      life: 3000,
    });
  };

  const showInfo = (detail) => {
    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: detail,
      life: 3000,
    });
  };

  const showWarn = (detail) => {
    toast.current.show({
      severity: "warn",
      summary: "Aviso",
      detail: detail,
      life: 3000,
    });
  };

  const showError = (detail) => {
    toast.current.show({
      severity: "error",
      summary: "Erro",
      detail: detail,
      life: 3000,
    });
  };

  const filterColumnsId = [1491, 2785, 990, 991, 992];
  const columnsNames = [];

  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  useEffect(() => {
    setSelectedOperacoes([]);
    if (securityFilters.securityValues?.country?.id) findOperacoes();
  }, [securityFilters.securityValues?.country?.id]);

  async function findOperacoes() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/carga-operaciones-forzadas/findyOperationes",
        {
          params: {
            codPais: securityFilters.securityValues?.country?.id,
          },
        }
      );
      if (status === 200) {
        setOperaciones(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function deleteItemGrid() {
    if (selectedProduct.length > 0) {
      const indicesToRemove = selectedProduct.map((element) => element.index);

      const newDatagrid = datasGrid.filter(
        (value) => !indicesToRemove.includes(value.index)
      );

      const newSelectedProduct = selectedProduct.filter(
        (value) => !indicesToRemove.includes(value.index)
      );

      setDatasGrid(newDatagrid);
      setSelectedProduct(newSelectedProduct);

      const vinsUnicos = new Set(newDatagrid.map((value) => value.id));
      setTotalVins(vinsUnicos.size);
      setTotalOperacoes(newDatagrid.length);
    }
  }

  function selectAll() {
    setSelectedProduct([...datasGrid]);
  }
  function deselectAll() {
    setSelectedProduct([]);
  }

  function removeDuplicatesGrid(listaGrade) {
    const unicosLista = [];

    for (let i = 0; i < listaGrade.length; i++) {
      let isUnique = true;

      for (let j = 0; j < unicosLista.length; j++) {
        if (
          listaGrade[i].id === unicosLista[j].id &&
          listaGrade[i].CodOpSelecionada === unicosLista[j].CodOpSelecionada
        ) {
          isUnique = false;
          break;
        }
      }

      if (isUnique) {
        unicosLista.push(listaGrade[i]);
      }
    }

    if (unicosLista.length !== listaGrade.length) {
      showInfo("El VIN ya se encuentra ingresado en la Grilla"); //id: 1024
    }

    setDatasGrid(unicosLista);
    setTotalOperacoes(unicosLista.length);
    const vinsUnicos = new Set(unicosLista.map((value) => value.id));
    setTotalVins(vinsUnicos.size);
  }

  function adicionarNaGrade(array) {
    setVin("");

    const newArray = [];
    let nextIdAux = nextId;
    selectedOperacoes.forEach((opSelec) => {
      array?.forEach((value) => {
        const newValue = {
          ...value,
          opSelecionada: opSelec.label,
          CodOpSelecionada: opSelec.id,
          index: nextIdAux,
        };
        nextIdAux += 1;
        setNextId(nextId + 1);
        newArray.push(newValue);
      });
    });

    setLoadingCargaMassiva(false);
    setDatasGrid([...datasGrid, ...newArray]);
    setTotalOperacoes([...datasGrid, ...newArray].length);
    setSelectedOperacoes([]);
    removeDuplicatesGrid([...datasGrid, ...newArray]);
  }

  async function findInStockBy() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/carga-operaciones-forzadas/findInStockBy",
        {
          params: {
            codPais: securityFilters.securityValues?.country?.id,
            codCliente: "90136500",
            codCuenta: 22,
            codVin: vin,
          },
        }
      );
      if (status === 200) {
        adicionarNaGrade(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findInStockByListVin(listVins: {}[]) {
    setLoadingCargaMassiva(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/carga-operaciones-forzadas/findInStockByListVin",
        { listVins },
        {
          params: {
            codPais: securityFilters.securityValues?.country?.id,
            codCliente: "90136500",
            codCuenta: 22,
          },
        }
      );
      if (status === 200) {
        showSuccess("Su transacción se realizó con éxito."); //id: 36
        adicionarNaGrade(data);
      }
      setLoadingCargaMassiva(false);
    } catch (error) {
      setLoadingCargaMassiva(false);
      console.error(error);
    }
  }

  async function aProcesar() {
    setLoadingProcess(true);
    if (datasGrid.length === 0)
      return showWarn("No hay operaciones seleccionadas");
    if (selectedProduct.length === 0)
      return showWarn("Ninguna operación pendientes seleccionada");

    const arreyAux: {}[] = [];
    selectedProduct.map((op) => {
      arreyAux.push({
        codVin: op.id,
        codOperacion: op.CodOpSelecionada,
        descripcion: op.opSelecionada,
      });
    });

    if (selectedProduct.length === 0)
      return showError("No hay operaciones seleccionadas.");

    const userData = localStorage.getItem("loginData");
    const authenticatedUser = JSON.parse(userData);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/carga-operaciones-forzadas/saveList",
        arreyAux,
        {
          params: {
            codPais: securityFilters.securityValues?.country?.id,
            codCliente: securityFilters.securityValues.client.id,
            codCuenta: securityFilters.securityValues.account.id,
            codUsuario: authenticatedUser.userName,
          },
        }
      );

      if (status === 200) {
        setLoadingProcess(false);
        if (selectedProduct.length > 1)
          showSuccess("Operaciones procesadas con éxito!");
        if (selectedProduct.length === 1)
          showSuccess("Operacion procesada con éxito!");

        const indicesToRemove = selectedProduct.map((element) => element.index);

        const newDatagrid = datasGrid.filter(
          (value) => !indicesToRemove.includes(value.index)
        );
        setDatasGrid(newDatagrid);
        setSelectedProduct([]);
        setTotalOperacoes(newDatagrid.length);
      }
      setLoadingProcess(false);
    } catch (error) {
      setLoadingProcess(false);
      console.error(error);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      selectedOperacoes.length > 0
        ? findInStockBy()
        : showWarn("No hay operaciones seleccionadas");
    }
  };

  function cargaMassiva(event) {
    const file = event.target.files[0];
    processFile(file);
  }

  const processFile = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData: any[][] = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        });

        if (jsonData.length > 0) {
          const columnsObject = jsonData[0].map((col) => ({
            name: col,
          }));

          const rowsDatas = jsonData.slice(1).map((row) => {
            return columnsObject.reduce((acc, col, index) => {
              let cellValue = row[index];
              if (typeof cellValue === "number") {
                const parsedDate = XLSX.SSF.parse_date_code(cellValue);
                if (parsedDate) {
                  cellValue = new Date(
                    parsedDate.y,
                    parsedDate.m,
                    parsedDate.d + 1
                  )
                    .toISOString()
                    .split("T")[0];
                }
              } else if (typeof cellValue !== "string") {
                cellValue = String(cellValue);
              }
              acc[col?.name] = cellValue;
              return acc;
            }, {});
          });
          findInStockByListVin(rowsDatas);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const checksecurityFilters = () => {
    if (
      securityFilters?.securityValues?.client &&
      securityFilters.securityValues?.client?.value &&
      securityFilters.securityValues?.account &&
      securityFilters.securityValues?.account?.value
    ) {
      return true;
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Pais, Clientes e Contas Obrigatorias",
        life: 3000,
      });
      return false;
    }
  };

  async function searchOperacoes(event: any) {
    await loadOperacoes(event.query);
  }

  async function loadOperacoes(query = "") {
    const check = checksecurityFilters();
    if (check) {
      try {
        const { status, data } = await sipcoAxiosService.get(
          "/carga-operaciones-forzadas/findyOperationes",
          {
            params: {
              codPais: securityFilters.securityValues?.country?.value,
            },
          }
        );
        if (status === 200) {
          setOperaciones(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  return (
    <div className="carga-op-forzadas">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={(e) => console.log(e)}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      />

      <Fieldset
        legend={
          <LanguageProvider id={"TODO"} alt="Consulta Operaciones Pendientes" />
        }
        className="marginTop"
      >
        <div className="horizontal-band">
          {/* <div className={"sipco-option"}>
            <label>{LanguageProvider({ id: 2720, alt: "Operaciones" })}</label>
            <MultiSelect
              onChange={(e) => setSelectedOperacoes(e.value)}
              filter
              value={selectedOperacoes}
              options={operaciones}
              optionLabel="label"
              placeholder={LanguageProvider({ id: 2720, alt: "Operaciones" })}
              maxSelectedLabels={3}
            />
          </div> */}

          <div className={"sipco-option"} style={{ maxWidth: "50%" }}>
            <label>{LanguageProvider({ id: 2720, alt: "Operaciones" })}</label>
            {/* aqui */}
            <AutoComplete
              value={selectedOperacoes}
              suggestions={operaciones}
              completeMethod={searchOperacoes}
              onChange={(e) => setSelectedOperacoes(e.value)}
              field="label"
              dropdown
              multiple
            />
          </div>

          <div className={"sipco-option"}>
            <label>{LanguageProvider({ id: 17088, alt: "Vin" })}</label>
            <InputText
              value={vin}
              onChange={(e) => setVin(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>

          <div className={"sipco-option"}>
            <Button
              label={LanguageProvider({ id: "TODO", alt: "Carga Massiva" })}
              loading={loadingCargaMassiva}
              onClick={() => {
                if (selectedOperacoes.length > 0) {
                  fileInputRef.current.click();
                } else {
                  showWarn("Ninguna operación seleccionada");
                }
              }}
            ></Button>
          </div>
          <input
            className="button-import-file"
            ref={fileInputRef}
            onChange={cargaMassiva}
            type="file"
            accept=".xls,.xlsx,.csv"
          ></input>
        </div>

        <div className="horizontal-band">
          <div className={"sipco-option"}>
            <label>
              {LanguageProvider({ id: 22029, alt: "Total de VIN's" })}
            </label>
            <InputText value={totalVins.toString()} disabled />
          </div>

          <div className={"sipco-option"}>
            <label>
              {LanguageProvider({ id: 22030, alt: "Total de operaciones" })}
            </label>
            <InputText value={totalOperacoes.toString()} disabled />
          </div>
        </div>

        <div className="width100">
          <DataTable
            value={datasGrid}
            selection={selectedProduct}
            onSelectionChange={(e) => setSelectedProduct(e.value)}
            metaKeySelection={false}
            dragSelection
            selectionMode="multiple"
            tableStyle={{ minWidth: "100%" }}
            footer={
              <div className="style-footer">
                <ExportButtons
                  dt={dt}
                  data={dataFooter}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            }
          >
            <Column field="id" header={columnsNames[0].label} />
            <Column field="opSelecionada" header={columnsNames[1].label} />
            <Column field="stockPlaya.calle" header={columnsNames[2].label} />
            <Column field="stockPlaya.columna" header={columnsNames[3].label} />
            <Column field="stockPlaya.nivel" header={columnsNames[4].label} />
          </DataTable>
        </div>

        <div className="footer-horizontal-band">
          <Button
            loading={loadingProcess}
            label={LanguageProvider({ id: "16283", alt: "Procesar" })}
            text
            onClick={aProcesar}
          />

          <Button
            label={LanguageProvider({ id: "750", alt: "Limpiar Grilla" })}
            text
            onClick={() => {
              setDatasGrid([]);
              setSelectedProduct([]);
              setTotalOperacoes(0);
              setTotalVins(0);
            }}
          />

          <Button
            label={LanguageProvider({ id: "26", alt: "Baja" })}
            text
            onClick={deleteItemGrid}
          />

          <Button
            label={LanguageProvider({ id: "748", alt: "Seleccionar todo" })}
            text
            onClick={selectAll}
          />

          <Button
            label={LanguageProvider({ id: "749", alt: "Deseleccionar todo" })}
            text
            onClick={deselectAll}
          />
        </div>
      </Fieldset>
    </div>
  );
}
