import "./TranferenciaPlaya.scss";

import React from "react";

import usePageViews from "../../../hooks/usePageViews";
import useAuth from "@shared/AuthContext";
import TranferenciaPlayaForm from "./components/TranferenciaPlayaForm";
import TranferenciaPlayaBrForm from "./components/TranferenciaPlayaBrForm";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function TranferenciaPlaya() {
  const SCREEN_CODE = "fun0041_transferencia_playa"; 
  ScreenCodeValue(SCREEN_CODE);
  const { user } = useAuth();
  usePageViews();

  function CountryForm() {
    if (user.pais.codPais === 2) {
      return <TranferenciaPlayaBrForm SCREEN_CODE={SCREEN_CODE} />;
    } else {
      return <TranferenciaPlayaForm SCREEN_CODE={SCREEN_CODE} />;
    }
  }


  return (
    <div className="tranferencia-playa">
     <CountryForm />
    </div>
  );
}
export default TranferenciaPlaya;
