import "./scss/header.scss";
import "primeicons/primeicons.css";

import React, { useContext, useMemo, useRef } from "react";
import useAuth, { AuthContext } from "./AuthContext";

import { Button } from "primereact/button";
import { LanguageProvider } from "@shared/components/language-provider";
import { Menu } from "primereact/menu";
import SearchModal from "@shared/components/search/SearchModal";
import { ThemeContext } from "../App";
import logo from "../themes/media/logo/logo-dark.png";
import { useNavigate } from "react-router-dom";
import settings from "../settings.json";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const navigate = useNavigate();
  const { changeCurrentTheme } = useContext(ThemeContext);
  const { user, logout } = useAuth();
  const menu = useRef(null);
  const environment = settings.Environment;

  const items = useMemo(
    () => [
      {
        items: [
          // {
          //   key: "profile",
          //   label: "Profile",
          //   command: () => {
          //     navigate("/user");
          //   },
          //   icon: "pi pi-user",
          // },
          // {
          //   key: "light-theme",
          //   label: "Light Theme",
          //   command: () => changeCurrentTheme("lara-light"),
          //   icon: "pi pi-sun",
          // },
          // {
          //   key: "dark-theme",
          //   label: "Dark Theme",
          //   command: () => changeCurrentTheme("lara-dark"),
          //   icon: "pi pi-moon",
          // },
          /*{
            key: "add-screen",
            label: "Add Screen",
            command: () => {
              navigate("/add-screen");
            },
            icon: "pi pi-cog",
          },*/
          {
            key: "logout",
            label: "Logout",
            command: () => {
              logout();
            },
            icon: "pi pi-power-off",
          },
        ],
      },
    ],
    [navigate]
  );

  const showMenu = (event: any) => {
    menu.current.toggle(event);
  };

  function isColor() {
    if (environment.toLowerCase() === "dev") {
      return settings.STAGE;
    } else if (environment.toLowerCase() === "prod") {
      return settings.PROD;
    }
  }
  const color = isColor();

  return (
    <div className="header-wrapper" >
      <header style={{background: `linear-gradient(to right, #5998ce, ${color})`}}>
        <div className="logo">
          <img src={logo} alt="Logo" />
          <p>SIPCO SYSTEM</p>
        </div>
        <div className="search">
          <SearchModal />
        </div>
        <div className="info login-info">
          <div className="country-info">
            <p>Pais: {user.pais.descripcion}</p>
          </div>
          <div className="button-menu">
            <Button
              label={user.userName}
              onClick={showMenu}
              aria-controls="popup_menu"
              size="small"
              icon="pi pi-user"
            />
            <Menu id="popup_menu" model={items} popup ref={menu} />
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
