import "./IngressoPlaya.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function IngressoPlaya() {
  const SCREEN_CODE = "con0022_ingreso_playa";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);

  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterColumnsId = [
    1396, 1491, 449, 466, 10774, 2496, 396, 408, 491, 4169, 18202, 376, 4197,
    9896, 9895, 4102, 12103, 2978, 4724, 22102,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }
  const mensagens = [];
  const ids = [
    1182, 4102, 10531, 65, 5583, 5044, 54, 1791, 467, 468, 449, 10774, 396, 408,
    491, 748, 749, 466, 2496, 1396, 1491, 4169, 376, 4197, 9896, 9895, 12103,
    2978, 18202, 10021, 10034, 4724, 22102,
  ];

  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [loading, setLoading] = useState(false);
  const [playas, setPlayas] = useState([]);
  const [mercados, setMercados] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [colores, setColores] = useState([]);
  const [familias, setFamilias] = useState([]);

  const [loadingMercados, setLoadingMercados] = useState(false);
  const [loadingPlayas, setLoadingPlayas] = useState(false);
  const [loadingMarcas, setLoadingMarcas] = useState(false);
  const [loadingModelos, setLoadingModelos] = useState(false);
  const [loadingColores, setLoadingColores] = useState(false);
  const [loadingFamilias, setLoadingFamilias] = useState(false);

  const [filter, setFilter] = useState({
    fechaInicio: new Date(),
    fechaFinal: new Date(),
    gfc06: false,
    gfc83: false,
    gfc40: false,
    gfc50: false,
    gfc76: false,
    gfc80: false,
    playas: [],
    mercados: [],
    marcas: [],
    modelos: [],
    colores: [],
    familias: [],
  });

  async function loadMercados() {
    setLoadingMercados(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "ingresso-playa/mercados",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );
      if (status === 200) {
        setMercados(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
  }
  async function loadMarcas() {
    setLoadingMarcas(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "ingresso-playa/marca",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );
        if (status === 200) {
          setMarcas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
  }
  async function loadModelos() {
    setLoadingModelos(true);
    try {
      if (filter.marcas.length === 1) {
        const selectedBrand = filter.marcas[0];
        const { status, data } = await sipcoAxiosService.get(
          "ingresso-playa/modelos",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: selectedBrand.id,
            },
          }
        );
        if (status === 200) {
          setModelos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
  }
  async function loadColores() {
    setLoadingColores(true);
    try {
      if (filter.marcas.length === 1) {
        const selectedBrand = filter.marcas[0];
        const { status, data } = await sipcoAxiosService.get(
          "ingresso-playa/colores",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: selectedBrand.id,
            },
          }
        );
        if (status === 200) {
          setColores(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColores(false);
    }
  }
  async function loadFamilias() {
    setLoadingFamilias(true);
    try {
      if (filter.marcas.length === 1) {
        const selectedBrand = filter.marcas[0];
        const { status, data } = await sipcoAxiosService.get(
          "ingresso-playa/familias",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: selectedBrand.id,
            },
          }
        );
        if (status === 200) {
          setFamilias(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFamilias(false);
    }
  }
  async function loadPlayas() {
    setLoadingPlayas(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "subcuentas-options",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCuenta: securityFilters.securityValues.account.id,
            descripcion: "",
          },
        }
      );
      if (status === 200) {
        setPlayas(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlayas(false);
    }
  }

  async function loadReportData() {
    setLoading(true);
    try {
      let transacciones = [];
      if (filter.gfc06) {
        transacciones.push("GFC06");
      }
      if (filter.gfc40) {
        transacciones.push("GFC40");
      }
      if (filter.gfc50) {
        transacciones.push("GFC50");
      }
      if (filter.gfc76) {
        transacciones.push("GFC76");
      }
      if (filter.gfc80) {
        transacciones.push("GFC80");
      }
      if (filter.gfc83) {
        transacciones.push("GFC83");
      }
      const { status, data } = await sipcoAxiosService.post(
        "ingresso-playa/report",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCliente: securityFilters?.securityValues?.client?.id || "",
          clienteDescripcion:
            securityFilters?.securityValues?.client?.description || "",
          codCuenta: securityFilters?.securityValues?.account?.id || null,
          transacciones: transacciones,
          playas: filter?.playas?.map((x) => x.codSubcuenta) || [],
          mercado: filter?.mercados?.map((x) => x.id) || [],
          marcas: filter?.marcas?.map((x) => x.id) || [],
          modelos: filter?.modelos?.map((x) => x.id) || [],
          colores: filter?.colores?.map((x) => x.id) || [],
          familias: filter?.familias?.map((x) => x.id) || [],
          rangeFecha: [filter.fechaInicio, filter.fechaFinal],
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const playas = await loadPlayas();
          const mercados = await loadMercados();
          const marcas = await loadMarcas();
          setFilter({
            ...filter,
            playas: playas,
            mercados: mercados,
            marcas: marcas,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  useEffect(() => {
    async function handleMarcaChange() {
      if (filter?.marcas?.length === 1) {
        try {
          const modelos = await loadModelos();
          const colores = await loadColores();
          const familias = await loadFamilias();
          setFilter({
            ...filter,
            familias,
            colores,
            modelos,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleMarcaChange();
  }, [filter.marcas]);

  return (
    <div className="ingresso-playa">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.gfc06}
                  onChange={(e) => {
                    setFilter({ ...filter, gfc06: e.checked });
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>GFC06-Recepcíon de Transferencia</label>
              </div>
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.gfc83}
                  onChange={(e) => {
                    setFilter({ ...filter, gfc83: e.checked });
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>GFC83-Devolucion o Reingreso a Playa</label>
              </div>
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.gfc40}
                  onChange={(e) => {
                    setFilter({ ...filter, gfc40: e.checked });
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>GFC40-Ingreso a Stock Fabricacíon Nacional</label>
              </div>
            </div>
            <div className="form-row">
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.gfc50}
                  onChange={(e) => {
                    setFilter({ ...filter, gfc50: e.checked });
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                  disabled={filter.gfc76 || filter.gfc80}
                />
                <label>GFC50-Disponibilida Vehículos Importados</label>
              </div>
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.gfc76}
                  onChange={(e) => {
                    setFilter({ ...filter, gfc76: e.checked });
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                  disabled={filter.gfc50}
                />
                <label>GFC76-Bajada de Buque</label>
              </div>
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.gfc80}
                  onChange={(e) => {
                    setFilter({ ...filter, gfc80: e.checked });
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                  disabled={filter.gfc50}
                />
                <label>GFC80-Ingreso a Playa Fiscal (Impo Terrestre)</label>
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"441"} alt="Playas" />
                </label>
                <MultiSelect
                  value={filter.playas}
                  options={playas}
                  onChange={(e) => {
                    setFilter({ ...filter, playas: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="descripcion"
                  loading={loadingPlayas}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10774"} alt="Mercados" />
                </label>
                <MultiSelect
                  value={filter.mercados}
                  options={mercados}
                  onChange={(e) => {
                    setFilter({ ...filter, mercados: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingMercados}
                  showClear
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"23888"} alt="Marcas" />
                </label>
                <MultiSelect
                  value={filter.marcas}
                  options={marcas}
                  onChange={(e) => {
                    setFilter({ ...filter, marcas: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingMarcas}
                  showClear
                />
              </div>
              {filter?.modelos?.length === 1 && (
                <>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"408"} alt="Modelos" />
                    </label>
                    <MultiSelect
                      value={filter.modelos}
                      options={modelos}
                      onChange={(e) => {
                        setFilter({ ...filter, modelos: e.value });
                      }}
                      display="chip"
                      filter
                      optionLabel="label"
                      loading={loadingModelos}
                      showClear
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"491"} alt="Colores" />
                    </label>
                    <MultiSelect
                      value={filter.colores}
                      options={colores}
                      onChange={(e) => {
                        setFilter({ ...filter, colores: e.value });
                      }}
                      display="chip"
                      filter
                      optionLabel="label"
                      loading={loadingColores}
                      showClear
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"10034"} alt="Familias" />
                    </label>
                    <MultiSelect
                      value={filter.familias}
                      options={familias}
                      onChange={(e) => {
                        setFilter({ ...filter, familias: e.value });
                      }}
                      display="chip"
                      filter
                      optionLabel="label"
                      loading={loadingFamilias}
                      showClear
                    />
                  </div>
                </>
              )}
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <Calendar
                  value={filter.fechaInicio}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaInicio: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <Calendar
                  value={filter.fechaFinal}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaFinal: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="1197" alt="Ingresso a Playas" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"IngresoPlaya"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loading}
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default IngressoPlaya;
