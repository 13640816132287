import React from 'react';
import ExportButtons from "@shared/components/Export";

const PanelHeader = ({ panelName, dt, data, columnsNames }) => {
    return (
        <div style={{ display: 'flex', width: "100%" , justifyContent: 'space-between', alignItems: 'center' }}>
            <h3>{panelName}</h3>
            <ExportButtons dt={dt} data={data} columns={columnsNames} screenName={"ConsultaGeneralTransacciones"} />
        </div>
    );
};

export default PanelHeader;