import "./ConsultaBloqueos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ConsultaBloqueos() {
  const SCREEN_CODE = "con0053_bloqueos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);

  const columns = [];
  const filterLabelId = [
    11335, 11334, 11336, 396, 2139, 2140, 1491, 10681, 11085, 11086, 11072,
    16907,
  ];
  const filterNames = [];
  const filterColumnsId = [
    11072, 10766, 3410, 10747, 2368, 10794, 11126, 3344, 4589, 11127, 1491,
    1569, 11072, 1143, 1669, 11097, 2368, 4589, 1491, 1569, 11072, 1143, 1669,
    11097, 4589,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    54, 65, 396, 467, 468, 1143, 1144, 1182, 1184, 1491, 1569, 1669, 1791, 2139,
    2140, 2368, 2502, 2785, 3344, 3410, 4102, 4589, 4791, 5044, 5583, 10681,
    10747, 10766, 10794, 11072, 11085, 11086, 11097, 11125, 11126, 11127, 11129,
    11334, 11335, 11336, 16907,
  ];

  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    marca: null,
    modelo: null,
    paiseDestino: null,
    color: null,
    codigoBloqueo: null,
    vin: "",
    anoFabricacion: null,
    anoModelo: null,
    fechaInicio: new Date(),
    fechaFinal: new Date(),
    tipoConsulta: "bloqAct",
  });

  const [loading, setLoading] = useState(false);

  const [loadingMarcas, setLoadingMarcas] = useState(false);
  const [marcas, setMarcas] = useState([]);
  const [loadingModelos, setLoadingModelos] = useState(false);
  const [modelos, setModelos] = useState([]);
  const [loadingColores, setLoadingColores] = useState(false);
  const [colores, setColores] = useState([]);
  const [loadingPaisDestino, setLoadingPaisDestino] = useState(false);
  const [paisesDestino, setPaisesDestino] = useState([]);
  const [loadingCodigosBloqueo, setLoadingCodigosBloqueo] = useState(false);
  const [codigosBloqueo, setCodigosBloqueo] = useState([]);
  const [vins, setVins] = useState([]);
  const [reportData, setReportData] = useState(null);

  async function loadMarcas() {
    setLoadingMarcas(true);
    try {
      if (
        securityFilters?.securityValues?.client?.id &&
        securityFilters?.securityValues?.country?.id
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "consulta-bloqueo/marca",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: 0, label: "Todos" });
          setMarcas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
  }
  async function loadModelos() {
    setLoadingModelos(true);
    try {
      if (
        securityFilters?.securityValues?.client?.id &&
        securityFilters?.securityValues?.country?.id
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "consulta-bloqueo/modelos",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );

        if (status === 200) {
          data.unshift({ id: 0, descripcion: "Todos" });
          setModelos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
  }
  async function loadColores() {
    setLoadingColores(true);
    try {
      if (
        securityFilters?.securityValues?.client?.id &&
        securityFilters?.securityValues?.country?.id
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "consulta-bloqueo/colores",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcionFull: "Todos" });
          setColores(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColores(false);
    }
  }
  async function loadPaisDestino() {
    setLoadingPaisDestino(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "consulta-bloqueo/pais-destino",
        {
          params: {
            description: "",
          },
        }
      );
      if (status === 200) {
        data.unshift({ id: 0, descripcion: "Todos" });
        setPaisesDestino(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPaisDestino(false);
    }
  }
  async function loadCodigoBloqueo() {
    setLoadingCodigosBloqueo(true);
    try {
      if (
        securityFilters?.securityValues?.client?.id &&
        securityFilters?.securityValues?.country?.id &&
        securityFilters?.securityValues?.account?.id
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "consulta-bloqueo/tipo-bloqueos",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codCuenta: securityFilters.securityValues.account.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcionFull: "Todos" });
          setCodigosBloqueo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCodigosBloqueo(false);
    }
  }
  async function searchVins(value: string) {
    await loadVins(value);
  }
  async function loadVins(query = "") {
    try {
      if (
        securityFilters?.securityValues?.client?.id &&
        securityFilters?.securityValues?.country?.id &&
        securityFilters?.securityValues?.account?.id &&
        filter.marca
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "consulta-bloqueo/vins",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codCuenta: securityFilters.securityValues.account.id,
              codMarca: filter.marca.id,
              codVin: query,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setVins(
              data.filter((x: any) =>
                x.id.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setVins(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "consulta-bloqueo/report",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCliente: securityFilters?.securityValues?.client?.id || "",
          clienteDescripcion:
            securityFilters?.securityValues?.client?.description || "",
          codCuenta: securityFilters?.securityValues?.account?.id || null,
          codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
          marca: filter?.marca?.id || null,
          modelo: filter?.modelo?.id || null,
          color: filter?.color?.id || null,
          vin: filter?.vin || null,
          paisDestino: filter?.paiseDestino?.id || null,
          anoFabricacion: filter?.anoFabricacion?.getFullYear() || null,
          anoModelo: filter?.anoModelo?.getFullYear() || null,
          tipoBloqueo: filter?.codigoBloqueo?.id || null,
          rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
          bloqAct: filter?.tipoConsulta === "bloqAct",
          bloqHistVin: filter?.tipoConsulta === "bloqHistVin",
          bloqHist: filter?.tipoConsulta === "bloqHist",
        }
      );

      if (status === 200) {
        setReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const [marcas, colores, paisesDestino, codigosBloqueo, modelos] =
          await Promise.all([
            loadMarcas(),
            loadColores(),
            loadPaisDestino(),
            loadCodigoBloqueo(),
            loadModelos(),
          ]);
        setFilter({
          ...filter,
          marca: marcas[0],
          color: colores[0],
          codigoBloqueo: codigosBloqueo[0],
          modelo: modelos[0],
          paiseDestino: paisesDestino[0],
          vin: null,
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  const formattedReportData = convertDateObjects(reportData?.filas);

  return (
    <div className="consulta-bloqueos">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <RadioButton
                checked={filter.tipoConsulta === "bloqAct"}
                onChange={(e) => {
                  setFilter({ ...filter, tipoConsulta: "bloqAct" });
                  setReportData(null);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider
                  id={filterNames[1].label}
                  alt="Bloqueos Actuales"
                />
              </label>
              <RadioButton
                checked={filter.tipoConsulta === "bloqHistVin"}
                onChange={(e) => {
                  setFilter({ ...filter, tipoConsulta: "bloqHistVin" });
                  setReportData(null);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider
                  id={filterNames[2].label}
                  alt="Histórico de Bloqueos de Vines en Stock"
                />
              </label>
              <RadioButton
                checked={filter.tipoConsulta === "bloqHist"}
                onChange={(e) => {
                  setFilter({ ...filter, tipoConsulta: "bloqHist" });
                  setReportData(null);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider
                  id={filterNames[3].label}
                  alt="Histórico de Bloqueos"
                />
              </label>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[3].id} alt="Marca" />
                </label>
                <Dropdown
                  value={filter.marca}
                  options={marcas}
                  onChange={(e) => setFilter({ ...filter, marca: e.value })}
                  optionLabel="label"
                  loading={loadingMarcas}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[7].id} alt="País Destino" />
                </label>
                <Dropdown
                  value={filter.paiseDestino}
                  options={paisesDestino}
                  onChange={(e) =>
                    setFilter({ ...filter, paiseDestino: e.value })
                  }
                  optionLabel="descripcion"
                  loading={loadingPaisDestino}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[4].id} alt="Modelo" />
                </label>
                <Dropdown
                  value={filter.modelo}
                  options={modelos}
                  onChange={(e) => setFilter({ ...filter, modelo: e.value })}
                  optionLabel="descripcion"
                  loading={loadingModelos}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11085"} alt="Año Fabricacion" />
                </label>
                <Calendar
                  value={filter.anoFabricacion}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, anoFabricacion: e.value });
                  }}
                  dateFormat="yy"
                  view="year"
                  hideOnRangeSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11086"} alt="Año Modelo" />
                </label>
                <Calendar
                  value={filter.anoModelo}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, anoModelo: e.value });
                  }}
                  dateFormat="yy"
                  view="year"
                  hideOnRangeSelection
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[5].id} alt="Colores" />
                </label>
                <Dropdown
                  value={filter.color}
                  options={colores}
                  onChange={(e) => setFilter({ ...filter, color: e.value })}
                  optionLabel="descripcionFull"
                  loading={loadingColores}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[10].id}
                    alt="Código Bloqueo"
                  />
                </label>
                <Dropdown
                  value={filter.codigoBloqueo}
                  options={codigosBloqueo}
                  onChange={(e) =>
                    setFilter({ ...filter, codigoBloqueo: e.value })
                  }
                  optionLabel="descripcionFull"
                  loading={loadingCodigosBloqueo}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[6].id}
                    alt="Ingrese el VIN"
                  />
                </label>
                <AutoComplete
                  value={filter.vin}
                  suggestions={vins}
                  completeMethod={(e) => searchVins(e.query)}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  onSelect={(e) => setFilter({ ...filter, vin: e.value })}
                  field="id"
                  delay={600}
                  dropdown
                  disabled={!filter.marca || filter.marca.id == 0}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <Calendar
                  value={filter.fechaInicio}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaInicio: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                  disabled={filter.tipoConsulta != "bloqHist"}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <Calendar
                  value={filter.fechaFinal}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaFinal: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                  disabled={filter.tipoConsulta != "bloqHist"}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="11130" alt="Consulta de Bloqueos" />
              </span>
              <div className="action">
                {/* <ExportButtons
                  dt={dt}
                  data={formattedReportData}
                  columns={columnsNames}
                  screenName={"Consulta-Bloqueo"}
                /> */}
              </div>
            </div>
          );
        }}
      >
        {filter.tipoConsulta == "bloqAct" && (
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={formattedReportData}
            paginator
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            loading={loading}
            resizableColumns
            removableSort
            filterDisplay="menu"
          >
            <Column field="0" header={columnsNames[0].label} sortable filter />
            <Column field="1" header={columnsNames[1].label} sortable filter />
            <Column field="2" header={columnsNames[2].label} sortable filter />
            <Column field="3" header={columnsNames[3].label} sortable filter />
            <Column field="4" header={columnsNames[4].label} sortable filter />
            <Column field="5" header={columnsNames[5].label} sortable filter />
            <Column field="6" header={columnsNames[6].label} sortable filter />
            <Column field="7" header={columnsNames[7].label} sortable filter />
            <Column field="8" header={columnsNames[8].label} sortable filter />
            <Column field="9" header={columnsNames[9].label} sortable filter />
          </DataTable>
        )}
        {filter.tipoConsulta == "bloqHistVin" && (
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={formattedReportData}
            paginator
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            loading={loading}
            resizableColumns
            removableSort
            filterDisplay="menu"
          >
            <Column
              field="0"
              header={<LanguageProvider id="1491" alt="VIN" />}
              sortable
              filter
            />
            <Column
              field="1"
              header={<LanguageProvider id="1569" alt="Operación" />}
              sortable
              filter
            />
            <Column
              field="2"
              header={<LanguageProvider id="11072" alt="Cod. Bloqueo" />}
              sortable
              filter
            />
            <Column
              field="3"
              header={<LanguageProvider id="1143" alt="Bloquear Piezas" />}
              sortable
              filter
            />
            <Column
              field="4"
              header={<LanguageProvider id="1669" alt="Observación" />}
              sortable
              filter
            />
            <Column
              field="5"
              header={<LanguageProvider id="11097" alt="Responsable" />}
              sortable
              filter
            />
            <Column
              field="6"
              header={<LanguageProvider id="2368" alt="Fecha Bloqueo" />}
              sortable
              filter
            />
            <Column
              field="7"
              header={<LanguageProvider id="4589" alt="Fecha Movimiento" />}
              sortable
              filter
            />
          </DataTable>
        )}
        {filter.tipoConsulta == "bloqHist" && (
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={formattedReportData}
            paginator
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            loading={loading}
            resizableColumns
            removableSort
            filterDisplay="menu"
          >
            <Column
              field="0"
              header={<LanguageProvider id="1491" alt="VIN" />}
              sortable
              filter
            />
            <Column
              field="1"
              header={<LanguageProvider id="1569" alt="Operación" />}
              sortable
              filter
            />
            <Column
              field="2"
              header={<LanguageProvider id="11072" alt="Cod. Bloqueo" />}
              sortable
              filter
            />
            <Column
              field="3"
              header={<LanguageProvider id="1143" alt="Bloquear Piezas" />}
              sortable
              filter
            />
            <Column
              field="4"
              header={<LanguageProvider id="1669" alt="Observación" />}
              sortable
              filter
            />
            <Column
              field="5"
              header={<LanguageProvider id="11097" alt="Responsable" />}
              sortable
              filter
            />
            <Column
              field="6"
              header={<LanguageProvider id="4589" alt="Fecha Movimiento" />}
              sortable
              filter
            />
          </DataTable>
        )}
      </Panel>
    </div>
  );
}
export default ConsultaBloqueos;
