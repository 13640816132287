import React, { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { Tag } from "primereact/tag";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
// import { Button } from "primereact/button";
// import { Dialog } from "primereact/dialog";

const MoveHistory: React.FC<{ vinData: any }> = ({ vinData }) => {
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [isDialogVisible, setDialogVisibility] = useState(false);
  const dt = useRef(null);
  const data = convertDateObjects(vinData?.reporteHistoricoMovimentos.filas);

  const filterColumnsId = [
    2131, 1166, 449, 921, 466, 294, 266, 492, 256, 4061, 1142, 1142, 1142, 1142,
    1142, 3625, 1, 2205, 11370, 16571, 16572, 2892, 3625, 0, 382, 275, 16557,
    4492, 319, 16558, 5628, 9564, 9620, 753, 5354, 16239,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const getRowStyles = (data) => {
    let className = "";

    if (selectedRow && JSON.stringify(selectedRow) === JSON.stringify(data)) {
      className = "highlight-row";
    }

    if (data[5]) {
      if (data[5].trim() !== "" && !data[4].includes("GFC55")) {
        className += " color-remito ";
      }
    }

    if (data[4]) {
      if (data[4].includes("GFC14")) {
        className += " color-despacho ";
      }

      if (data[4].includes("GFC40") || data[4].includes("GFC50")) {
        className += " color-ingreso ";
      }
    }

    if (data[23] !== null) {
      if (Number(data[23]) === 1) {
        className += " color-remito-error ";
      }
    }

    if (data[4] && data[4].includes("GFC01")) {
      if (data[17] !== null) {
        if (Number(data[17]) === 1) {
          className += " color-ingreso-por-saneamiento ";
        } else if (Number(data[17]) === 2) {
          className += " color-ingreso-saneamiento-12-meses ";
        } else {
          className += " color-ingreso-taller ";
        }
      }
    }

    return className.trim();
  };

  return (
    <div className="MoveHistory">
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}></span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"MoveHistory"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={getRowStyles}
          filterDisplay="menu"
          selectionMode="single"
          onSelectionChange={(e) => {
            setSelectedRow(e.value);
          }}
          selection={selectedRow}
        >
          {/* <Column field="0" header={columnsNames[0].label} sortable filter /> */}
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          {/* <Column field="17" header={columnsNames[17].label} sortable filter /> */}
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          {/* <Column field="23" header={columnsNames[23].label} sortable filter /> */}
        </DataTable>
        <div className="tags">
          <Tag value={columnsNames[24].label} className="tag-despacho" />
          <Tag value={columnsNames[25].label} className="tag-remitos" />
          <Tag value={columnsNames[26].label} className="tag-remito-error" />
          <Tag value={columnsNames[27].label} className="tag-ingreso" />
          <Tag value={columnsNames[28].label} className="tag-ingreso-taller" />
          <Tag value={columnsNames[29].label} className="tag-ingreso-por-saneamiento" />
          <Tag value={columnsNames[30].label} className="tag-ingreso-por-saneamiento-12-meses" />
        </div>
        {/* FALTA IMPLEMENTAR LOGICA DOS BOTOES NO BACKEND */}

        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            marginTop: 20,
          }}
        >
          <Button
            onClick={() => {
              if (!selectedRow) {
                setDialogVisibility(true);
              } else if (
              
                JSON.stringify(data[5]).replace(" ", "") == ""
              ) {
                setDialogVisibility(true);
              }
            }}
          >
            {columnsNames[31].label}
          </Button>
          <Button
          onClick={() => {
            if (!selectedRow) {
            } else {
            }
          }}
          >
            {columnsNames[32].label}
          </Button>
        </div> */}
        {/* <Dialog
          header={columnsNames[35].label}
          visible={isDialogVisible}
          style={{ width: "15vw" }}
          onHide={() => setDialogVisibility(false)}
          footer={dialogFooter}
        >
          <p className="m-4">
            {!selectedRow
              ? columnsNames[33].label
              : JSON.stringify(data[5]).replace(" ", "") == ""
                ? columnsNames[34].label
                : ""}
          </p>
        </Dialog> */}
      </Panel>
    </div>
  );
};
export default MoveHistory;
