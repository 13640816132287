import useAuth, { AuthContext } from "@shared/AuthContext";

import en from "../../language/en-US.json";
import esAR from "../../language/es-AR.json";
import esCL from "../../language/es-CL.json";
import esMX from "../../language/es-MX.json";
import fr from "../../language/fr-FR.json";
import pt from "../../language/pt-BR.json";
import { useContext } from "react";

// interface LanguageProviderProps {
//   id?: string;
//   alt?: string;
// }

export const LanguageProvider = (props) => {
  const { user } = useAuth();

  let label = "";

  switch (user?.idioma.codigoIso) {
    case "en-us":
      label = en[props.id];
      break;
    case "fr-fr":
      label = fr[props.id];
      break;
    case "es-mx":
      label = esMX[props.id];
      break;
    case "es-cl":
      label = esCL[props.id];
      break;
    case "pt-br":
      label = pt[props.id];
      break;
    case "es-ar":
    default:
      label = esAR[props.id];
      break;
  }
  if (label) return label;
  if (props.alt) return props.alt;
  if (props.id) return props.id;
  return "";
};
