import "./scss/main-layout.scss";
import { Outlet } from "react-router-dom";
import React from "react";
import Footer from "@shared/footer";
import NavMenu from "./navMenu/nav-menu";
const MainLayout = () => {
    return (
    <div className="main-layout">
      <NavMenu />
      <div className="body">
        <div className="page-content">
          <div className="page-body">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
