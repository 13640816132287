import "./scss/login-layout.scss";

import React, { useEffect, useState } from "react";

import LoadingIndicator from "./loading-indicator";
import { Outlet } from "react-router-dom";

const LoginLayout = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) return <LoadingIndicator />;
  return (
    <div className="login-layout">
      <div className="content">
        <div className="body">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
