import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { PrimeReactContext } from "primereact/api";
import { ThemeContext } from "../App";

const ThemeLayout = () => {
  const [currentTheme, setCurrentTheme] = useState("lara-light");
  const [loading, setLoading] = useState(true);

  const { changeTheme } = useContext(PrimeReactContext);

  function changeCurrentTheme(theme) {
    try {
      let prevTheme = "lara-dark";
      if (theme === "lara-light") {
        prevTheme = "lara-dark";
      } else {
        prevTheme = "lara-light";
      }
      setCurrentTheme(theme);
      localStorage.setItem("currentTheme", theme);
      changeTheme(prevTheme, theme, "theme-link");
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setLoading(false);
    const localStorageCurrentTheme = localStorage.getItem("currentTheme");
    if (localStorageCurrentTheme !== null) {
      changeCurrentTheme(localStorageCurrentTheme);
    } else {
      changeCurrentTheme("lara-light");
    }
  }, []);

  if (!loading)
    return (
      <ThemeContext.Provider value={{ currentTheme, changeCurrentTheme }}>
        <Outlet />
      </ThemeContext.Provider>
    );
};

export default ThemeLayout;
