import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function Despachos() {
  const SCREEN_CODE = "con0025_despachos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();

  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterLabelId = [2621, 2622, 449, 10774, 2496, 369, 266, 396];
  const filterNames = [];
  const filterColumnsId = [
    54, 382, 1491, 932, 266, 159, 164, 3186, 3069, 10774, 2496, 396, 408, 491,
    10021, 16808, 16809, 369, 449, 421, 9892, 5003, 6261, 16556, 11372, 16810,
    1143, 1947, 15157, 15142, 15146, 14712, 2521,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    2, 54, 65, 159, 164, 266, 369, 382, 396, 408, 421, 449, 467, 468, 491, 530,
    748, 749, 932, 1143, 1182, 1491, 1791, 1947, 2496, 3069, 3186, 5003, 5044,
    5583, 6261, 9892, 10021, 10531, 10774, 11372, 15142, 15146, 15157, 16531,
    16556, 16808, 16809, 16810, 14712, 2521, 292,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    fechaInicio: new Date(),
    fechaFinal: new Date(),
    playas: [],
    mercado: [],
    origens: [],
    destinos: [],
    concesionarios: [],
    marcas: [],
  });

  const [loading, setLoading] = useState(false);

  const [playas, setPlayas] = useState([]);
  const [mercado, setMercado] = useState([]);
  const [origens, setOrigens] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [concesionarios, setConcesionarios] = useState([]);
  const [marcas, setMarcas] = useState([]);

  const [loadingPlayas, setLoadingPlayas] = useState(true);
  const [loadingMercado, setLoadingMercado] = useState(true);
  const [loadingOrigens, setLoadingOrigens] = useState(true);
  const [loadingDestinos, setLoadingDestinos] = useState(true);
  const [loadingConcesionarios, setLoadingConcesionarios] = useState(true);
  const [loadingMarcas, setLoadingMarcas] = useState(true);

  async function loadPlayas() {
    setLoadingPlayas(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/despachos/playas",
          {
            params: {
              codCuenta: securityFilters?.securityValues?.account?.id,
            },
          }
        );

        if (status === 200) {
          const playas = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setPlayas(playas);
          return playas;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlayas(false);
    }
    return [];
  }

  async function loadMercados() {
    setLoadingMercado(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/despachos/mercado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const mercados = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setMercado(mercados);
          return mercados;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercado(false);
    }
    return [];
  }

  async function loadOrigens() {
    setLoadingOrigens(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/despachos/origen",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const origens = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setOrigens(origens);
          return origens;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigens(false);
    }
    return [];
  }

  async function loadDestinos() {
    setLoadingDestinos(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/despachos/destino",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const destinos = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setDestinos(destinos);
          return destinos;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinos(false);
    }
    return [];
  }

  async function loadConcesionarios() {
    setLoadingConcesionarios(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/despachos/concesionario",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id || "TODOS",
            },
          }
        );

        if (status === 200) {
          const concesionario = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setConcesionarios(concesionario);
          return concesionario;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConcesionarios(false);
    }
    return [];
  }

  async function loadMarca() {
    setLoadingMarcas(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/despachos/marca",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id || "TODOS",
            },
          }
        );

        if (status === 200) {
          const marca = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setMarcas(marca);
          return marca;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const playas = await loadPlayas();
          const mercados = await loadMercados();
          const origens = await loadOrigens();
          const destinos = await loadDestinos();
          const concesionarios = await loadConcesionarios();
          const marcas = await loadMarca();

          setFilter({
            ...filter,
            playas: playas,
            mercado: mercados,
            origens: origens,
            destinos: destinos,
            concesionarios: concesionarios,
            marcas: marcas,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || null,
        clienteDescripcion:
          securityFilters?.securityValues?.client?.description || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,

        rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
        playas: filter?.playas?.map((x) => x.id) || [],
        mercado: filter?.mercado?.map((x) => x.id) || [],
        origens: filter?.origens?.map((x) => x.id) || [],
        destinos: filter?.destinos?.map((x) => x.id) || [],
        concesionarios: filter?.concesionarios?.map((x) => x.id) || [],
        marcas: filter?.marcas?.map((x) => x.id) || [],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/report/despachos",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[0].label} alt="Desde" />
              </label>
              <Calendar
                value={filter.fechaInicio}
                onChange={(e) => {
                  setFilter({ ...filter, fechaInicio: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[1].label} alt="Hasta" />
              </label>
              <Calendar
                value={filter.fechaFinal}
                onChange={(e) => {
                  setFilter({ ...filter, fechaFinal: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[2].label} alt="Playas" />
              </label>
              <MultiSelect
                value={filter.playas}
                options={playas}
                onChange={(e) => {
                  setFilter({ ...filter, playas: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingPlayas}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[3].label} alt="Mercados" />
              </label>
              <MultiSelect
                value={filter.mercado}
                options={mercado}
                onChange={(e) => {
                  setFilter({ ...filter, mercado: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingMercado}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[4].label} alt="Origen" />
              </label>
              <MultiSelect
                value={filter.origens}
                options={origens}
                onChange={(e) => {
                  setFilter({ ...filter, origens: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingOrigens}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[5].label} alt="Destinos" />
              </label>
              <MultiSelect
                value={filter.destinos}
                options={destinos}
                onChange={(e) => {
                  setFilter({ ...filter, destinos: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingDestinos}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[6].label}
                  alt="Concesionarios"
                />
              </label>
              <MultiSelect
                value={filter.concesionarios}
                options={concesionarios}
                onChange={(e) => {
                  setFilter({ ...filter, concesionarios: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingConcesionarios}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[7].label} alt="Marca" />
              </label>
              <MultiSelect
                value={filter.marcas}
                options={marcas}
                onChange={(e) => {
                  setFilter({ ...filter, marcas: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingMarcas}
                showClear
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[46].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Despachos"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loading}
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          <Column field="23" header={columnsNames[23].label} sortable filter />
          <Column field="24" header={columnsNames[24].label} sortable filter />
          <Column field="25" header={columnsNames[25].label} sortable filter />
          <Column field="26" header={columnsNames[26].label} sortable filter />
          <Column field="27" header={columnsNames[27].label} sortable filter />
          <Column field="28" header={columnsNames[28].label} sortable filter />
          <Column field="29" header={columnsNames[29].label} sortable filter />
          <Column field="30" header={columnsNames[30].label} sortable filter />
          <Column field="31" header={columnsNames[31].label} sortable filter />
          <Column field="32" header={columnsNames[32].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default Despachos;
