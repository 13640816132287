import "./export.scss";

import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import React from "react";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";

interface ExportButtonsProps {
  dt: React.RefObject<DataTable<any>>;
  data: any[];
  columns: any[];
  screenName: string;
  csv?: boolean;
  xls?: boolean;
  pdf?: boolean;
}

const ExportButtons: React.FC<ExportButtonsProps> = ({
  dt,
  data,
  columns,
  screenName,
  csv = true,
  xls = true,
  pdf = true,
}) => {
  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  // Metodo diferente suprimindo o erro do autotable
  // import("jspdf").then((jsPDF) => {
  //     import("jspdf-autotable").then(() => {
  //         const doc : any = new jsPDF.default('p', 'pt');
  //
  //         doc.autoTable({ columns: columns, body: tableRowsToExport });
  //         doc.save(database.name + "_" + new Date().toLocaleString() + ".pdf");
  //     });
  // });

  const exportPdf = () => {
    const doc = new jsPDF("p", "pt");
    autoTable(doc, {
      columns: columns.map((column) => column.label),
      body: data,
    });
    doc.save(screenName + ".pdf");
  };

  // if (Array.isArray(dt.current.props.children)) {
  //     columns = dt.current.props.children.map((column) => column.props.header);
  // }
  // if (Array.isArray(dt.current.props.children)) {
  //     columns = dt.current.props.children.map((column) => column.props.header);
  // }
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const dataColumn = [columns.map((column) => column.label), ...data];
      const worksheet = xlsx.utils.json_to_sheet(dataColumn, {
        skipHeader: true,
      });
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      console.log(data);
      console.log("COLUNAS", columns);
      saveAsExcelFile(excelBuffer, screenName);
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, fileName + "_export" + EXCEL_EXTENSION);
      }
    });
  };

  return (
    <div className="export-report-button-container">
      {csv && (
        <Button
          className="icon-centered"
          type="button"
          icon="pi pi-file"
          rounded
          onClick={exportCSV}
          data-pr-tooltip="CSV"
        />
      )}
      {xls && (
        <Button
          className="icon-centered"
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="XLS"
        />
      )}
      {pdf && (
        <Button
          type="button"
          icon="pi pi-file-pdf"
          severity="warning"
          rounded
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      )}
    </div>
  );
};

export default ExportButtons;
