import { format, parseISO } from 'date-fns';

export function convertDateObjects(filas = []) {
    return filas.map(fila => {
        return fila.map(item => {
            if (item && typeof item === 'object' && item.hasOwnProperty('date')) {
                // Parse the date string into a Date object
                if (item.date === null) {
                    return "Sin fecha"
                }else{
                    const dateObject = parseISO(item.date);
                    // Format the Date object into a string
                    const formattedDate = format(dateObject, 'dd/MM/yyyy HH:mm:ss');
                    return formattedDate;
                }
            } else {
                // Return the original item if it's not an object with a 'date' property
                return item;
            }
        });
    });
}